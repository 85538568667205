<template>
  <div>
    <CostCard />
    <v-card
      color="element"
      class="mx-auto"
      max-width="640"
    >
      <v-card-title class="mobile-title">{{ $t('subscription.adequacyCheck.title') }}</v-card-title>
      <v-card-text class="mobile-text">
        <div>
          <p class="defaultText--text">{{ $t('subscription.adequacyCheck.text1') }}</p>
          <p class="defaultText--text">{{ $t('subscription.adequacyCheck.text2') }}</p>
          <v-form>
            <v-checkbox
              v-model="isFormValid"
              :label="$t('subscription.adequacyCheck.checkbox')"
              color="primary"
            ></v-checkbox>
          </v-form>
          <p
            class="primary--text text-decoration-underline abort-text"
            @click="openModal"
          >
            {{ $t('subscription.adequacyCheck.abort') }}
          </p>
        </div>
        <div
          class="pb-2 mt-3"
        >
          <CardNav
            :nextText="$t('subscription.adequacyCheck.next')"
            :prevText="$t('subscription.adequacyCheck.back')"
            @next="next"
            @previous="previousStep(1)"
            :nextDisabled="!isFormValid"
            :nextLoading="loading"
            :prevDisabled="loading"
          />
        </div>
      </v-card-text>
      <Modal
        :dialog="isModalOpen"
        @close="closeModal"
      >
        <template v-slot:content>
          <div class="mt-5 d-flex flex-column">
            <p class="font-weight-bold defaultText--text title">
              {{ $t('subscription.adequacyCheck.modal.title') }}
            </p>
            <p>
              {{ $t('subscription.adequacyCheck.modal.text') }}
            </p>
            <div
              class="pb-2 mt-3"
            >
              <CardNav
                :nextText="$t('subscription.adequacyCheck.modal.yes')"
                :prevText="$t('subscription.adequacyCheck.back')"
                @next="finish"
                @previous="closeModal"
              />
            </div>
          </div>
        </template>
      </Modal>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import Modal from '@/components/Shared/Modal.vue';
import CardNav from '@/components/Shared/CardNav.vue';

export default {
  name: 'AdequacyCheck',
  components: {
    CostCard: lazyLoad('components/Subscription/CostCard'),
    Modal,
    CardNav,
  },
  data() {
    return {
      isFormValid: false,
      isModalOpen: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions('subscription', [
      'previousStep',
      'nextStep',
      'confirmInadequacy',
    ]),
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    finish() {
      this.$router.push({ name: 'Marketplace' });
    },
    async next() {
      this.loading = true;
      try {
        await this.confirmInadequacy();
        this.nextStep(1);
      } catch (error) {
        this.$notify.error(error.message);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.abort-text {
  cursor: pointer;
  display: inline-block;
}

@media (max-width: 600px) {
  .mobile-title {
    font-size: 16px !important;
  }
  .mobile-text {
    font-size: 12px !important;
  }
}
</style>
